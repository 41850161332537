<template>
  <div
    :class="{
      'absolute right-0 bottom-0 w-10 lg:w-auto  mb-8 m-5 lg:m-10 border border-neutral-500 cursor-pointer rounded-full pl-0 p-2 lg:p-4 flex items-center gap-2 bg-white bg-opacity-10 backdrop-blur-sm opacity-70 hover:opacity-100 ': true,
      'pl-2': !isHovering,
    }"
    @mouseover="
      () => {
        isHovering = true;
      }
    "
    @mouseleave="
      () => {
        isHovering = false;
      }
    "
  >
    <audio ref="audio" loop preload="auto">
      <source type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
    <input
      type="range"
      min="0"
      max="1"
      step="0.01"
      v-model="volume"
      @input="updateVolume"
      :class="{
        ' custom-range opacity-0 w-0 transition-all h-1 lg:h-2 bg-white bg-opacity-40  rounded-lg appearance-none focus:no-underline cursor-pointer ': true,
        'opacity-100  lg:w-24': isHovering,
      }"
    />
    <button @click="toggleMute">
      <LucideVolumeX class="w-4 h-4 lg:w-8 lg:h-8" v-if="isMuted" /> <LucideVolume2 class="w-4 h-4 lg:w-8 lg:h-8" v-if="!isMuted" />
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, onUpdated } from "vue";
import { cmsStore } from '../stores/cmsStore';

const cms = cmsStore();
const audio = ref(null);
const isMuted = ref(true);
const volume = ref(0.8);
const isHovering = ref(false);
const music = computed(() => cms.app.music);

const assetsUrl = useRuntimeConfig().public.assetsUrl

const toggleMute = () => {
  audio.value.play().catch((error) => {
    console.error("Error al reproducir el audio:", error);
  });
  isMuted.value = !isMuted.value;
  audio.value.muted = isMuted.value;
};

const updateVolume = () => {
  audio.value.volume = volume.value;
};

onMounted(async () => {
  await cms.loadApp()
  audio.value.volume = volume.value;
  if (music.value) {
    audio.value.src = `${assetsUrl}/${music.value}`;
  }
})

watch(volume, (newVolume) => {
  if (isMuted.value) {
    audio.value.volume = newVolume;
  }
});
</script>
  
  <style scoped>
/* styles.css */

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none; /* Para Safari */
  appearance: none;
  width: 16px; /* Ancho de la bolita */
  height: 16px; /* Altura de la bolita */
  background: #ffffff; /* Color de la bolita */
  cursor: pointer;
  border-radius: 50%; /* Hace la bolita redonda */
  @apply opacity-0 lg:opacity-100;
}


</style>
  